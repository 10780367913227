<template>
    <article class="proyecto-el row-start" @click="show = !show" :show="show">
        <div class="contenedor-imagen">
            <img :src="data.image.url" alt="">
        </div>
        <div class="contenedor-texto" :open="show">
            <h2>{{ data.name }}</h2>
            <p v-if="show" v-html="data.header"></p>
            <p v-if="show" style="padding-bottom: 15px;"  v-html="data.info.replaceAll('\r\n','<br>')"> </p>
            <a v-if="show && data.href" :href="data.href" target="_blank"
                class="boton-verde waypoints animate__animated animate__fadeInUp">
                {{ $t('projects_grants_subsidies.btn_more_info') }}
            </a>
        </div>
    </article>
</template>


<script>
export default {
    name: 'proyecto',
    props: ['data'],
    data: () => ({
        show: false
    }),
}
</script>